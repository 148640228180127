<template>
  <v-banner id="lojas">

    <v-row class="text-center">
        <v-col cols="12">
            <h1 class="text-h3">Lojas</h1>
        </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-list>
          <v-list-item>
            <v-list-item-title class="text-center">Capital</v-list-item-title>
            </v-list-item>

          <v-list-item v-for="(loja, index) in capitais" :key="index" v-show="loja.ATIVO">
                <v-list-item-icon><v-icon>mdi-map-marker</v-icon></v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="cidade">{{ loja.CIDADE }}</v-list-item-title
              >
              <v-list-item-subtitle class="text--primary">{{loja.BAIRRO}}</v-list-item-subtitle>
              <v-list-item-subtitle>{{ loja.ENDERECO }}</v-list-item-subtitle>
            </v-list-item-content>
              <v-list-item-action>
                  <v-btn icon :href="loja.LINK" target="_blanck" title="Abrir Mapa"><v-icon>mdi-map</v-icon></v-btn>
              </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-col>
      <v-col cols="12">
        <v-list>
          <v-list-item>
            <v-list-item-title class="text-center">Interior</v-list-item-title>
            </v-list-item>
          <v-list-item v-for="(loja, index) in iteriores" :key="index" v-show="loja.ATIVO">
                <v-list-item-icon><v-icon>mdi-map-marker</v-icon></v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="cidade">{{ loja.CIDADE }}</v-list-item-title
              >
              <v-list-item-subtitle class="text--primary">{{loja.BAIRRO}}</v-list-item-subtitle>
              <v-list-item-subtitle>{{ loja.ENDERECO }}</v-list-item-subtitle>
            </v-list-item-content>
              <v-list-item-action>
                  <v-btn icon :href="loja.LINK" target="_blanck" title="Abrir Mapa"><v-icon>mdi-map</v-icon></v-btn>
              </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-col>
    </v-row>
    <v-divider class="my-2 primary" />
  </v-banner>
</template>

<script>
import lojasJson from "../../model/payload/lojas.json";

export default {
  name: "lojas",
  data() {
    return {
      lojas: [],
    };
  },
  computed:{
    capitais(){
      return this.lojas.filter(loja => loja.CAPITAL)
    },
    iteriores(){
      return this.lojas.filter(loja => !loja.CAPITAL)
    },
  },
  created() {
    this.lojas = lojasJson;
  },
};
</script>

<style scoped>
.cidade{
  font-size: 1.3em;
}
</style>
