<template>
  <v-banner shaped  id="apresentacao">
    <v-row>
      <v-col class="text-center" align-self="center" cols="12" md="9">
        <h1 class="my-5 text-md-h1 text-h2">Se é gás, <span class="fonte-raina">rainágas</span>!</h1>
        <v-btn rounded color="secondary" class="my-5 text-h4 text-capitalize" href="https://wa.me/558004040555" target="_blanck" x-large><span class="fonte-raina ">Pedido online</span></v-btn>
      <h3 class="my-5 text-subtitle-0">
          Rápido e fácil!
        </h3>
      </v-col>

      <v-col class="text-center" cols="12" md="3">
        <v-img src="../../assets/botijoes.png" 
          class="mx-auto" 
          contain 
          max-height="300"
        />
        <h6 class="text-caption text-small">Revendedor Autorizado</h6>
        <v-img
          src="../../assets/nacional-gas-logo.png"
          max-width="115"
          height="25"
          class="mx-auto"
          contain
        />
      </v-col>
    </v-row>
    <v-divider class="my-2 primary"/>
  </v-banner>
</template>

<script>
export default {
  name: "Apresentacao",
};
</script>

<style scoped>
.text-small{
  font-size: xx-small;
}
.text-large{
  font-size: xx-large;
}

</style>