<template>
<v-banner id="tutorial">
    <v-row class="text-center">
        <v-col cols="12">
            <h1 class="text-h3">Agilize seu pedido!</h1>
        </v-col>
    </v-row>
    <v-row class="text-center ">
        <v-col class="text-center" align-self="center"  cols="12" md="8">
            <div class="mb-16">
            <h1 class="text-h5">Passos para adicionar o site de pedido em seu smartphone:</h1>
            </div>
            <ul class="passos text-left " v-if="windowWidth > 800">
                <li class="text-h6" @click="modal = 0" :class="{'primary--text':(modal==0)}">1 - NO SEU CELULAR CLIQUE NOS 3 PONTOS LOCALIZADOS NO CANTO SUPERIOR DIREITO.</li>
                <li class="text-h6" @click="modal = 1" :class="{'primary--text':(modal==1)}">2 - PROCURE E CLIQUE NA OPÇÃO "ADICIONAR À TELA INICIAL".</li>
                <li class="text-h6" @click="modal = 2" :class="{'primary--text':(modal==2)}">3 - CLIQUE EM "ADICIONAR".</li>
                <li class="text-h6" @click="modal = 3" :class="{'primary--text':(modal==3)}">4 - CLIQUE EM "ADICIONAR AUTOMATICAMENTE".</li>
                <li class="text-h6" @click="modal = 4" :class="{'primary--text':(modal==4)}">5 - PRONTO!</li>
            </ul>
            <ul class="passos" v-else>
                <li class="text-h6 primary--text" v-show="modal==0">1 - NO SEU CELULAR CLIQUE NOS 3 PONTOS LOCALIZADOS NO CANTO SUPERIOR DIREITO.</li>
                <li class="text-h6 primary--text" v-show="modal==1">2 - PROCURE E CLIQUE NA OPÇÃO "ADICIONAR À TELA INICIAL".</li>
                <li class="text-h6 primary--text" v-show="modal==2">3 - CLIQUE EM "ADICIONAR".</li>
                <li class="text-h6 primary--text" v-show="modal==3">4 - CLIQUE EM "ADICIONAR AUTOMATICAMENTE".</li>
                <li class="text-h6 primary--text" v-show="modal==4">5 - PRONTO!</li>
            </ul>
            <h4>Não é aplicativo e não ocupa espaço na memória.</h4>
        </v-col>

        <v-col cols="12" md="4">
    <v-carousel v-model="modal" hide-delimiter-background show-arrows-on-hover cycle>
        <v-carousel-item key="1">
            <v-banner shaped id="tutorial">
                <v-row>
                    <v-col class="text-right" cols="12">
                        <v-img 
                        src="../../assets/1-removebg-preview.png"
                        max-width="500"
                        height="450"
                        class="mx-auto"
                        contain></v-img>
                    </v-col>
                    
                </v-row>
            </v-banner>
        </v-carousel-item>
        <v-carousel-item key="2">
            <v-row>
                    <v-col class="text-right" cols="12">
                        <v-img 
                        src="../../assets/2-removebg-preview.png"
                        max-width="500"
                        height="450"
                        class="mx-auto"
                        contain></v-img>
                    </v-col>
            </v-row>
        </v-carousel-item>
        <v-carousel-item key="3">
            <v-row>
                <v-col class="text-right" cols="12">
                    <v-img 
                        src="../../assets/3-removebg-preview.png"
                        max-width="500"
                        height="450"
                        class="mx-auto"
                        contain></v-img>
                </v-col>
            </v-row>
        </v-carousel-item>
        <v-carousel-item key="4">
            <v-row>
                <v-col class="text-right" cols="12">
                    <v-img 
                        src="../../assets/4-removebg-preview.png"
                        max-width="500"
                        height="450"
                        class="mx-auto"
                        contain></v-img>
                </v-col>
            </v-row>
        </v-carousel-item>
        <v-carousel-item key="5">
            <v-row>
                <v-col class="text-right" cols="12">
                    <v-img 
                        src="../../assets/5-removebg-preview.png"
                        max-width="500"
                        height="450"
                        class="mx-auto"
                        contain></v-img>
                </v-col>
            </v-row>
        </v-carousel-item>
    </v-carousel>
        </v-col>
        
    </v-row>
    <v-divider class="my-2 primary" />
</v-banner>
</template>

<script>
export default {
    name: "Tutorial",
    data() {
        return {
            windowWidth: window.innerWidth,
            modal:0
        }
    },
};
</script>

<style scoped>
.passos{
    list-style: none;
}
</style>