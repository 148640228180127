<template>
  <div>
    <apresentacao/>
    <missao/>
    <contato/>
    <tutorial/>
    <lojas/>
  </div>
</template>

<script>
import Apresentacao from '../section/Apresentacao.vue'
import Contato from '../section/Contato.vue'
import Lojas from '../section/Lojas.vue'
import Tutorial from '../section/Tutorial.vue'

export default {
  components: { Apresentacao, Contato, Lojas, Tutorial},
    name:"Inicio",
}
</script>

<style>
  
</style>