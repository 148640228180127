<template>
  <v-banner shaped id="contato">
    <v-row class="text-center">
        <v-col cols="12">
            <h1 class="text-h3">Canais de atendimento</h1>
        </v-col>
    </v-row>
    <v-row class="text-center" align="stretch" align-content="stretch">
        <v-col cols="12" md="4" align-self="stretch">
            <v-banner shaped >
                <v-icon  size="100" class="my-auto">mdi-phone</v-icon>
                <h2 class="text-h5">Televendas</h2>
                <v-btn text rounded class="red--text text-h4" href="tel:0800 4040 555">0800 4040 555</v-btn>
            </v-banner>
        </v-col>
        <v-col cols="12" md="4">
            <v-banner shaped>
                <v-icon  size="100" class="my-auto">mdi-whatsapp</v-icon>
                <h2 class="text-h5">Whatssap</h2>
                <v-btn text rounded class="red--text text-h4" href="https://wa.me/558004040555" target="_blanck">0800 4040 555</v-btn>
            </v-banner>
        </v-col>
        <v-col cols="12" md="4">
            <v-banner shaped>
                <v-icon  size="100" class="my-auto">mdi-instagram</v-icon>
                <h2 class="text-h5">Instagram</h2>
                <v-btn text rounded class="red--text text-h4" href="https://www.instagram.com/raina.gas/" target="_blanck">@raina.gas</v-btn>
            </v-banner>
        </v-col>
    </v-row>
    <v-divider class="my-2 primary"/>
  </v-banner>
</template>

<script>
export default {
    name:"Contato"
}
</script>

<style>

</style>