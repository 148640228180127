import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);
let dark = {
    primary: '#ECA15C',
    secondary: '#ED1D24',
    accent: '#82B1FF',
    error: '#FF5252',
    info: '#2196F3',
    success: '#4CAF50',
    warning: '#FFC107',
};

export default new Vuetify({
    theme: { 
        dark: true,
        themes:{
            dark
        }
    },
});
