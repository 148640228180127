<template>
  <span>
    <v-dialog v-model="dialog" width="500">
      <template v-slot:activator="{ on, attrs }">
        <v-btn text rounded v-bind="attrs" v-on="on">
          <slot></slot>
        </v-btn>
      
      </template>

      <v-card>
        <v-card-title>
          VENHA FAZER PARTE DO NOSSO TIME!
        </v-card-title>

        <v-card-text>
          Olá! Seja bem-vindo a Rainágas. Ficamos felizes em receber você aqui. Nos envie seu currículo, inserindo todas as informações! Assim, poderemos lhe conhecer melhor!
        </v-card-text>
        <v-card-text>
          Envie um email para curriculo@rainagas.com.br com o Titulo "CURRICULO [SEU NOME]", no assunto "CANDIDATO A [INFORME A VAGA PRETENDIDA]".
        </v-card-text>
        <v-card-text>
          Aguarde o nosso contato para futuros processos que se encaixem com o seu perfil. Ficaremos felizes em ter você como um de nossos colaboradores! Boa sorte!
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false"> Fechar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
export default {
  name: "Mdtrabalhe",
  data() {
    return {
      dialog: false,
      nome: "TRABALHE CONOSCO"
    };
  },
};
</script>

<style>
</style>