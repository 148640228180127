<template>
  <v-app>
  
        <v-card 
          elevation="0"
          rounded
          target="_blanck"
          href="https://wa.me/558004040555"
          class="baixo-direita text-center"
          color="transparent"
        >
      <v-card-subtitle>Faça aqui seu pedido!</v-card-subtitle>

        <v-card-text 
          style="
      text-align: -webkit-right;
          
          "
        >
          <v-img 
          width="35"
          src="./assets/whatsapp.png"/>
        </v-card-text>
        </v-card>

    <v-main class="page">
      <v-app-bar dense prominent fixed app>
        <v-app-bar-title>
          <v-img src="./assets/logo.png" max-width="250" height="100" contain />
        </v-app-bar-title>
        <v-spacer />
        <v-toolbar-items class="d-none d-sm-flex">
          <v-btn
            text
            href="https://wa.me/558004040555"
            target="_blanck"
            class="text-h5"
            >Pedidos</v-btn
          >
          <v-btn text @click="$vuetify.goTo('#contato')" 
            class="text-h5"
          >Contato</v-btn>
          <v-btn text @click="$vuetify.goTo('#lojas')" 
            class="text-h5"
          >Lojas</v-btn>
        </v-toolbar-items>
      </v-app-bar>
      <v-container fluid>
        <Inicio />
      </v-container>
    </v-main>

    <v-footer padless>
      <v-card flat tile width="100%" class="text-center">
        <v-card-text>
          <v-btn class="mx-4" text rounded @click="$vuetify.goTo('#app')"
            >Home</v-btn
          >
          <v-btn class="mx-4" text rounded @click="$vuetify.goTo('#lojas')"
            >Lojas</v-btn>

          <mdtrabalhe>Trabalhe Conosco </mdtrabalhe>

        </v-card-text>

        <v-card-text>
          RAINNA COMERCIO DE GÁS LTDA | CNPJ: 08.575.951/0001-09<br />
          ti@rainagas.com.br
        </v-card-text>

        <v-divider></v-divider>

        <v-card-text class="white--text">
          {{ new Date().getFullYear() }} — <strong>Yotta</strong>
        </v-card-text>
      </v-card>
    </v-footer>
  </v-app>
</template>

<script>
import Inicio from "./components/page/Inicio.vue";
import Mdtrabalhe from "./components/shared/Mdtrabalhe.vue";

export default {
  name: "App",

  components: {
    Inicio,
    Mdtrabalhe
  },

  data: () => ({
    //
  }),
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Montserrat+Alternates:ital,wght@1,600&display=swap");
.fonte-raina {
  font-family: "Montserrat Alternates", sans-serif;
}
.page {
  background-image: linear-gradient(rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.9)),
    url("./assets/perfil.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  /* background-size: 100em; */
}
.baixo-direita{
  position:fixed;
  right: 1em;
  bottom: 1em;
  z-index: 9999;

}
</style>
